import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { Suspense } from '@axiom/ui';
import { Outlet } from 'react-router';

import { MagicLinkPage } from './pages/MagicLinkPage';
import { TermsOfUsePage } from './pages/TermsOfUsePage';
import { SSOPage } from './pages/SSOPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { EnterEmailPage } from './pages/EnterEmailPage';
import { DefaultLayout } from './layouts/DefaultLayout';
import { Page } from './components/Page/Page';
import { Password } from './components/Password/Password';
import { SetPassword } from './components/SetPassword/SetPassword';
import { CheckEmailRedirect } from './components/CheckEmailRedirect/CheckEmailRedirect';
import { CheckYourEmail } from './components/CheckYourEmail/CheckYourEmail';
import { ExpiredLink } from './components/ExpiredLink/ExpiredLink';
import { Redirecter } from './components/Redirecter/Redirecter';
import { EnvUtil } from './utils/env-util';

export const Router = () => (
  <Routes>
    <Route
      element={
        <Page title="Axiom Law - Login">
          <DefaultLayout>
            <Suspense>
              <Outlet />
            </Suspense>
          </DefaultLayout>
        </Page>
      }
    >
      <Route path="/">
        <Route path="/email-token/:token" element={<MagicLinkPage />} />
        <Route path="client-login/*" element={<Redirecter to="../" />} />
        <Route
          path="sign-up"
          element={<Redirecter url={EnvUtil.signUpUrl} />}
        />
        <Route path="" element={<EnterEmailPage />} />
        <Route path="link-expired" element={<ExpiredLink />} />
        <Route path="sso" element={<SSOPage />} />
        <Route path="terms-of-use" element={<TermsOfUsePage />} />
        <Route path=":providedEmail">
          <Route path="" element={<CheckEmailRedirect />} />
          <Route path="password" element={<Password />} />
          <Route path="check-email" element={<CheckYourEmail />} />
          <Route path="forgot-password" element={<CheckYourEmail />} />
          <Route path="set-password" element={<SetPassword />}>
            <Route path=":token" element={<SetPassword />} />
          </Route>
        </Route>
      </Route>
    </Route>

    <Route path="/404" element={<NotFoundPage />} />
    <Route path="/*" element={<Navigate to="/404" />} />
  </Routes>
);
