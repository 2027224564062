import React, { useEffect } from 'react';
import { z } from 'zod';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SchemaEmail, SchemaPassword } from '@axiom/types';
import {
  ApiError,
  Button,
  CondensedLarge,
  CondensedMedium,
  CookieUtil,
  DetectBrowserUtil,
  Form,
  Gutter,
  Input,
  Well,
} from '@axiom/ui';

import sideImageUrl from '../../public/svg/weight-lifter.svg?url';
import { AuthApi } from '../../api/auth/auth';
import { ClientLoginTwoColumnLayout } from '../../layouts/ClientLogin/ClientLoginTwoColumnLayout';
import { PasswordStrengthUtil } from '../../utils/password-strength-util';
import { EnvUtil } from '../../utils/env-util';
import { WindowUtil } from '../../utils/window-util';
import { PasswordStrengthMeter } from '../PasswordStrengthMeter/PasswordStrengthMeter';
import { LegacyAuthApi } from '../../api/auth/legacy-auth';

const { getUrlWithRelayState } = WindowUtil;

const formSchema = z.object({
  password: SchemaPassword.refine(
    password => PasswordStrengthUtil.meetsRequirement(password),
    {
      message:
        'Your password is not strong enough. It must have a least 4 bars.',
    }
  ),
});
type FormData = Required<z.infer<typeof formSchema>>;
export type ClientResetPasswordProps = {
  action: string;
  email: string;
  token?: string;
};

export const ClientResetPassword: React.FC<ClientResetPasswordProps> = ({
  action,
  email,
  token,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const loginScreen = getUrlWithRelayState('/', {
    location,
  });

  useEffect(() => {
    if (!email || !SchemaEmail.safeParse(email).success) {
      navigate(
        getUrlWithRelayState('/', {
          location,
        })
      );
    }
  }, [location]);

  return (
    <ClientLoginTwoColumnLayout action={action} sideImageUrl={sideImageUrl}>
      <Form
        name="RESET_PASSWORD_FORM"
        onSubmit={async ({ password }: FormData, actions) => {
          try {
            if (token) {
              await LegacyAuthApi.updateTokenSetPassword(token, { password });
            }
            const { data: jwt } = await AuthApi.createAuth({
              email,
              password,
            });

            CookieUtil.setUserCookie(jwt, {
              domain: EnvUtil.cookieDomain,
              isSafari: DetectBrowserUtil.isSafari(),
            });

            navigate(getUrlWithRelayState(`/sso`, { location }));
          } catch (e) {
            if (!(e instanceof ApiError)) throw e;
            actions.setFieldError(
              'password',
              'An error occurred. Please try again or reach out to Axiom Answers for Clients.'
            );
          }
        }}
        schema={formSchema}
        initialValues={{ password: null }}
      >
        {({ fireSubmit, values: { password } }) => (
          <>
            <Gutter bottom="8px">
              <Well>
                <CondensedLarge>{email}</CondensedLarge>
              </Well>
            </Gutter>
            <Gutter bottom="24px">
              <Link to={loginScreen}>
                <CondensedMedium>
                  Not you? Sign in with a different account.
                </CondensedMedium>
              </Link>
            </Gutter>
            <Gutter bottom="16px">
              <Input label="Password" name="password" />
            </Gutter>
            <Gutter bottom="24px">
              <PasswordStrengthMeter password={password} />
            </Gutter>
            <Button fluid onClick={fireSubmit} name="set-password">
              Set Password
            </Button>
          </>
        )}
      </Form>
    </ClientLoginTwoColumnLayout>
  );
};
