// server.js is frickin es5 and complaining about exports so doing old standard
const EnvVars = {
  AFC_REDIRECT: 'AFC_REDIRECT',
  ENVOY_API_URL: 'ENVOY_API_URL',
  CLIENT_API_BASE: 'CLIENT_API_BASE',
  COOKIE_DOMAIN: 'COOKIE_DOMAIN',
  ENABLE_LOGGING: 'ENABLE_LOGGING',
  ENABLE_SUGGESTIONS: 'ENABLE_SUGGESTIONS',
  ENVIRONMENT_NAME: 'ENVIRONMENT_NAME',
  ENVOY_REDIRECT: 'ENVOY_REDIRECT',
  GA_ACCOUNT_ID: 'GA_ACCOUNT_ID',
  GITLAB_MERGE_ID: 'GITLAB_MERGE_ID',
  GITLAB_PROJECT_ID: 'GITLAB_PROJECT_ID',
  GTM_ACCOUNT_ID: 'GTM_ACCOUNT_ID',
  LD_CLIENT_ID: 'LD_CLIENT_ID',
  LD_FAKE_USER_ID: 'LD_FAKE_USER_ID',
  LOGGLY_CLIENT_ID: 'LOGGLY_CLIENT_ID',
  PENDO_APP_ID: 'PENDO_APP_ID',
  PORTAL_REDIRECT: 'PORTAL_REDIRECT',
  SIGN_UP_URL: 'SIGN_UP_URL',
};
let envs = {};
const EnvUtil = {
  initialize(env) {
    envs = this.pluckAllowedFrom(env);
  },

  getAllowedFrontendProcessVars() {
    return Object.values(EnvVars);
  },

  pluckAllowedFrom(obj) {
    const allowedProps = {};
    this.getAllowedFrontendProcessVars().forEach(propName => {
      allowedProps[propName] = obj[propName] || null;
    });

    return allowedProps;
  },

  get cookieDomain() {
    return envs[EnvVars.COOKIE_DOMAIN];
  },

  get envoyApiUrl() {
    return envs[EnvVars.ENVOY_API_URL];
  },

  get envoyRedirect() {
    return envs[EnvVars.ENVOY_REDIRECT];
  },

  get portalRedirect() {
    return envs[EnvVars.PORTAL_REDIRECT];
  },

  get afcRedirect() {
    return envs[EnvVars.AFC_REDIRECT];
  },

  get clientApiBase() {
    return envs[EnvVars.CLIENT_API_BASE] || '/api';
  },

  get gitLabMergeId() {
    return envs[EnvVars.GITLAB_MERGE_ID];
  },

  get gitLabProjectId() {
    return envs[EnvVars.GITLAB_PROJECT_ID];
  },

  get launchDarklyClientId() {
    return envs[EnvVars.LD_CLIENT_ID];
  },

  get launchDarklyFakeUserId() {
    return envs[EnvVars.LD_FAKE_USER_ID];
  },

  get gaAccountId() {
    return envs[EnvVars.GA_ACCOUNT_ID];
  },

  get gtmAccountId() {
    return envs[EnvVars.GTM_ACCOUNT_ID];
  },

  get logglyClientId() {
    return envs[EnvVars.LOGGLY_CLIENT_ID];
  },

  get environmentName() {
    return envs[EnvVars.ENVIRONMENT_NAME];
  },

  get enableLogging() {
    return (
      envs[EnvVars.ENABLE_LOGGING] === true ||
      envs[EnvVars.ENABLE_LOGGING] === 'true'
    );
  },

  get pendoAppId() {
    return envs[EnvVars.PENDO_APP_ID];
  },

  get enableSuggestions() {
    return (
      envs[EnvVars.ENABLE_SUGGESTIONS] === true ||
      envs[EnvVars.ENABLE_SUGGESTIONS] === 'true'
    );
  },

  get signUpUrl() {
    return envs[EnvVars.SIGN_UP_URL];
  },
};

try {
  EnvUtil.initialize(window?.ENV || {});
  // eslint-disable-next-line no-empty
} catch {}

// eslint-disable-next-line unicorn/prefer-module
module.exports = {
  EnvUtil,
};
