import React from 'react';
import { CondensedLarge, CondensedMedium, Gutter, Well } from '@axiom/ui';
import { Link, useLocation, useParams } from 'react-router-dom';

import imageUrl from '../../public/svg/chart-lady.svg?url';
import { ClientLoginTwoColumnLayout } from '../../layouts/ClientLogin/ClientLoginTwoColumnLayout';

export const CheckYourEmail = () => {
  const location = useLocation();
  const { providedEmail } = useParams();

  return (
    <ClientLoginTwoColumnLayout
      action="Check your email"
      subText={
        location.pathname.includes('check-email')
          ? "We found your account but it looks like you need to complete an additional step before you can sign in. We've sent  instructions to your email."
          : "We've sent sign-in instructions to your email."
      }
      sideImageUrl={imageUrl}
    >
      {providedEmail && (
        <Gutter bottom="8px">
          <Well name="EMAIL">
            <CondensedLarge>{providedEmail}</CondensedLarge>
          </Well>
        </Gutter>
      )}

      <Gutter bottom="24px">
        <CondensedMedium>
          <Link to="/" data-test="BACK">
            Not you? Sign in with a different account.
          </Link>
        </CondensedMedium>
      </Gutter>
      <CondensedMedium name="CHECK_EMAIL_BODY_COPY">
        If you don't receive that email within an hour, please check your spam
        folder before reaching out to Axiom Answers for{' '}
        <a href="mailto:client.answers@axiomlaw.com">Clients</a> or{' '}
        <a href="mailto:answers@axiomlaw.com">Talent</a> for assistance.
      </CondensedMedium>
    </ClientLoginTwoColumnLayout>
  );
};
