import { shape, string } from 'prop-types';

import { AjvUtil } from '../utils/ajv-util';
import { requestPassword } from '../api/auth/auth';
import GenericStore from '../classes/generic-store';

const FormRequestPasswordValidation = AjvUtil.compileSchema({
  required: ['email'],
  properties: {
    email: {
      title: 'Email',
      type: 'string',
      format: 'email',
      isNotEmpty: true,
    },
  },
});

class FormRequestPasswordStoreClass extends GenericStore {
  getDataShape() {
    return shape({
      email: string,
    });
  }

  load() {
    return this.setState({
      email: null,
    });
  }

  validate(data) {
    return AjvUtil.formatValidationForFinalForm(
      FormRequestPasswordValidation,
      data
    );
  }

  submit(formData) {
    return this.clearState(requestPassword(formData.email));
  }
}

export const FormRequestPasswordStore = new FormRequestPasswordStoreClass();
