import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import {
  Button,
  CondensedMedium,
  CookieUtil,
  DetectBrowserUtil,
  Form,
  Gutter,
  Input,
  UnauthorizedError,
  CondensedLarge,
  Well,
} from '@axiom/ui';
import { SchemaEmail } from '@axiom/types';

import sideImageUrl from '../../public/svg/weight-lifter.svg?url';
import { ClientLoginTwoColumnLayout } from '../../layouts/ClientLogin/ClientLoginTwoColumnLayout';
import { WindowUtil } from '../../utils/window-util';
import { createAuthPasswordRequestSchema, AuthApi } from '../../api/auth/auth';
import { EnvUtil } from '../../utils/env-util';
import { LegacyAuthApi } from '../../api/auth/legacy-auth';

const formSchema = createAuthPasswordRequestSchema.pick({ password: true });
type FormData = Required<z.infer<typeof formSchema>>;

const { getUrlWithRelayState } = WindowUtil;

export const Password: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { providedEmail } = useParams();
  const search = new URLSearchParams(location.search);

  useEffect(() => {
    if (!SchemaEmail.safeParse(providedEmail).success) {
      navigate(
        getUrlWithRelayState(`/`, {
          location,
        })
      );
    }
  }, [location, providedEmail]);

  const handleForgotPasswordClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();

    const body = {
      email: providedEmail,
      RelayState: search.get('RelayState') || undefined,
    };
    LegacyAuthApi.createForgotPassword(body);

    navigate(`../forgot-password`);
  };

  return (
    <ClientLoginTwoColumnLayout action="Sign in" sideImageUrl={sideImageUrl}>
      <Form
        name="PASSWORD_FORM"
        onSubmit={async (formData: FormData, actions) => {
          try {
            const { data: jwt } = await AuthApi.createAuth({
              ...formData,
              email: providedEmail,
            });
            CookieUtil.setUserCookie(jwt, {
              domain: EnvUtil.cookieDomain,
              isSafari: DetectBrowserUtil.isSafari(),
            });
            navigate(getUrlWithRelayState(`/sso`, { location }));
          } catch (e) {
            if (!(e instanceof UnauthorizedError)) {
              throw e;
            }
            actions.setFieldError(
              'password',
              'Invalid email/password. Please try again.'
            );
          }
        }}
        schema={formSchema}
        initialValues={{ password: null }}
      >
        {({ fireSubmit }) => {
          return (
            <>
              <Gutter bottom="8px">
                <Well>
                  <CondensedLarge>{providedEmail}</CondensedLarge>
                </Well>
              </Gutter>
              <Gutter bottom="24px">
                <CondensedMedium>
                  <Link
                    to={getUrlWithRelayState(`/`, {
                      location,
                    })}
                  >
                    Not you? Sign in with a different account.
                  </Link>
                </CondensedMedium>
              </Gutter>
              <Gutter bottom="8px">
                <Input label="Password" name="password" />
              </Gutter>
              <Gutter bottom="24px">
                <a
                  href="#"
                  onClick={handleForgotPasswordClick}
                  data-test="FORGOT_PASSWORD"
                >
                  Forgot password?
                </a>
              </Gutter>
              <Button fluid onClick={fireSubmit} name="sign-in">
                Sign in
              </Button>
            </>
          );
        }}
      </Form>
    </ClientLoginTwoColumnLayout>
  );
};
